<template>
  <div>
    <pro-document-breadcrumbs
      :breadcrumbs="[
        { name: 'menu.DepartmentSetting', icon: 'PROSmart-Setting' },
      ]"
      user-role="procurer"
    ></pro-document-breadcrumbs>
    <q-card style="margin-top: 10px">
      <q-tabs
        v-model="tab"
        dense
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
        <q-tab v-for="tab in tabs" :key="tab" :label="tab" :name="tab" />
      </q-tabs>
      <q-tab-panels v-model="tab" animated>
        <q-tab-panel
          class="scroll"
          v-for="items in data"
          :key="items"
          :name="`${items.name}`"
        >
          <q-card
            flat
            bordered
            v-for="section in items.value"
            :key="section.sectionName"
            style="margin-bottom: 10px"
          >
            <q-card-section style="padding-left: 0; padding-right: 0">
              <div
                class="text-h4"
                v-text="section.sectionName"
                style="padding: 0 16px 16px 16px"
              />

              <q-markup-table flat>
                <tbody>
                  <tr>
                    <td class="text-left" style="width: 80%">
                      <div
                        class="text-h5"
                        style="font-weight: bolder"
                        v-text="section.name"
                      />
                      <div class="text-h6" v-html="section.description" />
                    </td>
                    <td style="width: 20%">
                      <q-input
                        v-if="!section.type || section.type === 'string'"
                        :dense="true"
                        class="col-2"
                        outlined
                        v-model="section.value"
                      />
                      <q-select
                        v-if="
                          section.type &&
                          section.type.indexOf('|') !== -1 &&
                          section.type.indexOf('||') === -1
                        "
                        outlined
                        class="col-2"
                        :dense="true"
                        v-model="section.value"
                        :options="typeSplit(section.type)"
                      />
                      <template
                        v-if="section.type && section.type.indexOf('||') !== -1"
                      >
                        <q-option-group
                          class="col-2"
                          :options="optionGroup(section.type)"
                          type="checkbox"
                          v-model="section.value"
                        />
                      </template>
                      <el-input-number
                        v-if="section.type && section.type === 'number'"
                        class="col-2"
                        v-model="section.value"
                        @change="handleChange"
                        :min="1"
                      />
                      <q-toggle
                        dense
                        v-if="section.type && section.type === 'boolean'"
                        style="padding-bottom: 16px"
                        v-model="section.value"
                      />
                      <pro-json-setting
                        v-if="section.type && section.type === 'json'"
                        v-model="section.value"
                      />
                    </td>
                  </tr>
                </tbody>
              </q-markup-table>
            </q-card-section>
          </q-card>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
    <div class="row justify-end">
      <div class="col-auto">
        <q-btn
          @click="Save"
          style="margin-top: 16px; margin-bottom: 16px"
          color="primary"
          :label="getRes('System.Button.Save')"
          no-caps
        ></q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProJsonSetting from "@/components/PROSmart/Setting/ProJsonSetting.vue";

export default {
  components: { ProJsonSetting, ProDocumentBreadcrumbs },
  data() {
    return {
      data: [],
      tabs: [],
      tab: "",
    };
  },
  methods: {
    typeSplit(type) {
      return type.split("|");
    },
    typeSplits(type) {
      return type.split("||");
    },
    optionGroup(arrayString) {
      return arrayString.split("||").map((b) => {
        return { label: b, value: b };
      });
    },
    arrayGroupBy(array, groupParam) {
      const groupedArr = array.reduce((pre, item) => {
        const groupName = item[groupParam];
        pre[groupName] = [...(pre[groupName] || []), item];
        return pre;
      }, {});
      this.data = Object.keys(groupedArr).map((key) => {
        return {
          name: key,
          value: groupedArr[key],
        };
      });
    },
    getSetting() {
      this.$proSmart.setting.getAllDepartmentSetting(this).then((rs) => {
        this.data = rs;
        for (let i in this.data) {
          if (this.data[i].value === "True") {
            this.data[i].value = true;
          }
          if (this.data[i].value === "False") {
            this.data[i].value = false;
          }
          if (this.data[i].type && this.data[i].type.indexOf("||") !== -1) {
            this.data[i].value = this.data[i].value.split(",");
          }
        }
        this.arrayGroupBy(this.data, "projectCode");
        this.tabs = this.data.map((list) => list.name);
        if (this.tab === "") {
          this.tab = this.tabs[0];
        }
      });
    },
    Save() {
      const mirror_data = JSON.parse(JSON.stringify(this.data));
      const ready_data = [];
      mirror_data.forEach((items) => {
        items.value.forEach((item) => {
          ready_data.push(item);
        });
      });

      for (let i in ready_data) {
        for (let a in ready_data[i]) {
          if (ready_data[i][a] === null) {
            ready_data[i][a] += "";
          }
        }
        if (typeof ready_data[i].value === "number") {
          ready_data[i].value = ready_data[i].value.toString();
        }
        if (typeof ready_data[i].value === "boolean") {
          ready_data[i].value = ready_data[i].value.toString();
          const a = ready_data[i].value.charAt(0).toUpperCase();
          ready_data[i].value = a + ready_data[i].value.slice(1);
        }
        if (ready_data[i].type && ready_data[i].type.indexOf("||") !== -1) {
          ready_data[i].value = ready_data[i].value.join(",");
        }
      }
      this.$proSmart.setting
        .updateDepartmentSettingList(this, JSON.stringify(ready_data))
        .then(() => {
          this.$alert(
            this.$t("WorkflowOperation.OperationSucceed"),
            this.$t("notification"),
            { confirmButtonText: this.getRes("ok") }
          );
        });
    },
  },
  watch: {
    tab: {
      handler(New) {
        this.getSetting(New);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ {
  .high-light {
    color: #04b21e;
    font-weight: bolder;
  }
}
</style>
